import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectFmDialog from '@/pages/MainPage/dialogs/SelectFmDialogNew';
import SelectSsDialog from '@/pages/MainPage/dialogs/SelectSsDialogNew';
import UiCase from '@/pages/MainPage/datas/UiCase';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import openWindow from '@/script/openWindow.mjs';
import Permission from '@/components/Permission.jsx';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import SelectBmDialogNew from "../../dialogs/SelectBmDialogNew";
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateUi({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();
    const openModal = useOpenModal();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    // 初始化
    const init = async () => {
        ref.current = {};
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer, prjTypeCode, subTypeCode });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    // 新增
    const doAdd = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();
            formData.prjId = prj.prjId;
            formData.sVer = prj.sVer;
            formData.fmCode = ref.current.fmCode;
            formData.bmCode = ref.current.bmCode;
            formData.ssCode = ref.current.ssCode;

            const data = await runAsync(
                async () => {
                    return await http.post(`/umuis`, formData);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: false },
                success: { show: true },
            });

            onOk(data)
            openWindow('/UiDetail', { uiId: data.pkid });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调：设置项目
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer, prjTypeCode: value.prjTypeCode, subTypeCode: value.subTypeCode });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.fmCode = null;
        ref.current.bmCode = null;
        ref.current.ssCode = null;
        form.setFieldsValue({ fmName: '', bmName: '', ssName: '' });
    }

    // 回调：设置功能模块
    const callFm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectFmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.fmCode = result.fmCode;
            form.setFieldsValue({ fmName: result.fmName });
        }
    }

    // 回调：设置业务模块
    const callBm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectBmDialogNew params={{ prjId, sVer }} />);

        if (result) {
            ref.current.bmCode = result.bmCode;
            form.setFieldsValue({ bmName: result.bmName });
        }
    }

    // 回调：设置子系统
    const callSs = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectSsDialog params={{ prjId, sVer, isUi: 1 }} />);

        if (result) {
            ref.current.ssCode = result.ssCode;
            form.setFieldsValue({ ssName: result.ssName });
        }
    }

    return (
        <>
            <Modal
                title="新建用户界面"
                okText="保存"
                cancelText="取消"
                bodyStyle={{ padding: '12px 24px 2px' }}
                footer={[
                    <Space>
                        <Permission permission="UI_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目!' }]}>
                        <Input placeholder="请选择项目" readOnly addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} />
                    </Form.Item>

                    {prj.prjTypeCode == 'NEW' && prj.subTypeCode == 'BODA2' &&
                        <Form.Item name="bmName" label="业务域">
                            <Input placeholder="请选择业务域" readOnly addonAfter={<SearchOutlined onClick={callBm} />} />
                        </Form.Item>
                    }

                    {!(prj.prjTypeCode == 'NEW' && prj.subTypeCode == 'BODA2') &&
                        <Form.Item name="fmName" label="功能模块">
                            <Input placeholder="请选择功能模块" readOnly addonAfter={<SearchOutlined onClick={callFm} />} />
                        </Form.Item>
                    }

                    <Form.Item name="ssName" label="子系统">
                        <Input placeholder="请选择子系统" readOnly addonAfter={<SearchOutlined onClick={callSs} />} />
                    </Form.Item>

                    <Form.Item name="uiUserCode" label="界面代码" rules={[{ required: true, message: '请输入界面代码!' }]}  >
                        <Input placeholder="请输入界面代码" />
                    </Form.Item>

                    <Form.Item name="uiName" label="界面名称" rules={[{ required: true, message: '请输入界面名称!' }]}  >
                        <Input placeholder="请输入界面名称" />
                    </Form.Item>

                    <UiCase prjId={prj.prjId} sVer={prj.sVer} prjTypeCode={prj.prjTypeCode} />
                </Form>
            </Modal >

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
