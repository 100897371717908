import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import {NodeStylePane as StylePane} from '../../SidePanel/RightPanel/NodePane.jsx'
import meta from './metaAr.mjs'
import ArType from './ArType.jsx'
import PropertiesPane from './PropertiesPaneAr.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchAr.jsx'

const ArTypes = new Map(
    ArType
        .flat()
        .filter(a => Array.isArray(a))
        .map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,
        StylePane,

        canDeleteNode(map, node) {
            return this.canWriteTree(map, node)
        },

        canCopyStyle(map, node) {
            return true
        },

        canLinkType(map, node, type) {
            return [
                'AD',
                'ARRI',
                'BD',
                'BDALG',
                'BDBASE',
                'BDEVENT',
                'BDLOGIC',
                'BDRULE',
                'BDSUMMARY',
                'BF',
                'BFDATAFLOW',
                'CDT',
                'DE',
                'DO',
                'DT',
                'DV',
                'FC',
                'IE',
                'LSI',
                'OR',
                'OU',
                'SF',
                'UE',
                'UI',
                'UIALG',
                'UIRULE',
                'UV',
            ].includes(type)
        },

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        canWriteStyle(map, node) {
            return this.canWriteData(map, node)
        },

        castFrom(map, node) {
            const {bizNodeType} = node.data
            CommonNode.castFrom.call(this, map, node)

            // 尽量匹配类型
            if (ArTypes.has(bizNodeType)) {
                node.data = {...node.data, arTypeCode: bizNodeType}
            }
        },

        defaultChildType(map, node) {
            return this.type
        },

        getDesc(map, node) {
            const {arTypeCode} = node.data

            if (! arTypeCode || 'GENERAL' === arTypeCode) {
                return this.name
            }

            const {name = ''} = ArTypes.get(arTypeCode) ?? {}
            return name
        },

        getIcons(map, node) {
            const {arTypeCode} = node.data
            const {icon} = ArTypes.get(arTypeCode) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                backgroundColor: '#f8f7f7',
                shape: 'Rectangle',
            }
        },

        getTitle(map, node) {
            const {arTypeCode} = node.data
            const {name} = ArTypes.get(arTypeCode) ?? {}
            return name ?? CommonNode.getTitle.call(this, map, node)
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                [
                    '_O', '组织模件', [
                        ['OU'],
                        ['OR'],
                    ],
                ],

                [
                    '_B', '业务模件', [
                        ['BF'],
                        ['BFDATAFLOW'],
                        ['BD'],
                        ['BDSUMMARY'],
                        ['BDRULE_1'],
                        ['BDRULE'],
                        ['BDLOGIC'],
                        ['BDEVENT'],
                        ['BDALG_'],
                    ],
                ],

                [
                    '_A', '分析模件', [
                        ['DO'],
                        ['DE'],
                        ['IE'],
                        ['AD'],
                        ['ARRI'],
                    ],
                ],

                [
                    '_F', '功能模件', [
                        ['SF'],
                        ['UI'],
                        ['UV'],
                        ['UIRULE'],
                        ['UE_'],
                    ],
                ],

                ['LSI'],

                [
                    '_D', '库表模件', [
                        ['DT'],
                        ['DV'],
                        ['CDT'],
                    ],
                ],

                ['FC'],

                [
                    '_E', '外部项目模件', [
                        ['OU_1', '组织单元'],
                        ['OR_1', '岗位角色'],
                        ['DO_1', '维度对象'],
                        ['DE_1', '维度实体'],
                        ['IE_1', '指标实体'],
                        ['AD_1', '分析维度'],
                        ['BDSUMMARY_1', '用户场景'],
                        ['BDRULE_2', '数据实体'],
                        ['DT_1', '数据表'],
                    ],
                ]
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()
        },

        _fixChild(map, node, child) {
            this.castFrom(map, child)
            return true
        },

        _getDefaultDataFields(map, node) {
            const pd = node.parent.data

            const arTypeCode = (() => {
                if (
                    'AR' === pd.bizNodeType &&
                    'USER' === pd.arTypeCode
                ) {
                    return 'EXEC'
                }
                else if ('BDSUMMARY' === pd.bizNodeType) {
                    return 'USER'
                }
                else if ('OU_OR' === pd.bizNodeType) {
                    return 'EXECUTOR'
                }
                else {
                    return 'GENERAL'
                }
            })()

            // 不需要根据类型自动填充文本，故不调用父类的方法
            return {
                arTypeCode,
            }
        },
    })
}
