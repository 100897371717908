import extendNode from '../extendNode.mjs'
import useCatRootModuleNode from '../_CAT_ROOT_MODULE/useCatRootModuleNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootCm.jsx'
import meta from './metaCatRootCm.mjs'

export default () => {
    const CatRootModuleNode = useCatRootModuleNode()

    return extendNode(CatRootModuleNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(UC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'UC'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['UC'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['UC'],
            ]
        },
    })
}
